import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export type ChatWidgetStateType = string[];

export const ChatWidgetState = atom({
  key: "RECOIL_CHAT_WIDGET",
  default: [], // 開いているcometchatのguidの配列
  effects_UNSTABLE: [persistAtom], // reloadしても永続化
});
