import { useState, useEffect } from "react";
import { GetStaticProps } from "next";
import { useRouter } from "next/router";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import auth from "lib/auth";
import { useRecoilState } from "recoil";
import { ChatWidgetState, ChatWidgetStateType } from "recoil/chat";
import { ParsedUrlQuery } from "node:querystring";

// custom
import { useLoading } from "lib/loadingContext";

// mui
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MuiLink from "@mui/material/Link";
import Link from "next/link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ErrMsgs from "components/atoms/ErrMsgs";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// mui

export function addServerErrors<T>(
  errors: { [P in keyof T]?: string[] },
  setError: (fieldName: keyof T, error: { type: string; message: string }) => void,
) {
  return Object.keys(errors).forEach((key) => {
    setError(key as keyof T, {
      type: "server",
      // 注意". "でjoinしてることに依存してるコードあるので注意
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      message: errors[key as keyof T]!.join(". "),
    });
  });
}

const formSchema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
  otpAttempt: yup.string(),
});

type FormData = {
  email: string;
  password: string;
  otpAttempt: string;
  etc: string;
};

// Loginページからの遷移先に渡したいqueryStringで許可しているもの。
const permittedQueryString = ["from_kind", "token"];
const buildQueryString = (query: ParsedUrlQuery) => {
  const qs = Object.keys(query)
    .filter((key) => permittedQueryString.includes(key))
    .map((key) => `${key}=${query[key]}`)
    .join("&");
  return qs ? `?${qs}` : "";
};

const loginErrorMessages = [
  "ログインに失敗しました。メールアドレスまたはパスワードをご確認ください。",
  "パスワードがご不明の場合は「パスワードを設定される方はこちら」から再設定を行うことができます。",
  "Authentification failed. Please enter correct username and password",
];

const Login: React.FC = () => {
  const router = useRouter();
  const { setLoading } = useLoading();
  const [, setWidgetOpenedGUIDs] = useRecoilState<ChatWidgetStateType>(ChatWidgetState);
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({ resolver: yupResolver(formSchema) });

  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const [showNotifyMsg, setShowNotifyMsg] = useState<boolean>(false);

  const [showOtpInput, setShowOtpInput] = useState(false);

  useEffect(() => {
    if (router.query.notify_msg) {
      setShowNotifyMsg(true);
    }
  }, [router.query.notify_msg]);

  // /loginに来たら開いているchat widgetをすべて閉じる
  useEffect(() => {
    setWidgetOpenedGUIDs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data: FormData) => {
    clearErrors("etc");
    const res = await auth.login(data.email, data.password, data.otpAttempt);
    if (res.ok) {
      if (res.data.message === "otp_required") {
        setShowOtpInput(true);
      } else {
        const redirectTo = router.query.redirectTo || "/quotations";
        router.push(`${redirectTo}${buildQueryString(router.query)}`);
        // このページのみ、router.pushによるeventChangeのloadingがうまく動かないので、個別に入れておく。
        setLoading(true);
      }
    } else {
      addServerErrors({ etc: loginErrorMessages }, setError);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Snackbar
        open={showNotifyMsg}
        onClose={() => setShowNotifyMsg(false)}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="info" sx={{ width: "100%" }}>
          {router.query.notify_msg}
        </Alert>
      </Snackbar>

      <Box
        sx={{
          marginTop: 12,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
          {errors.etc?.message && <ErrMsgs msgs={errors.etc.message.split(". ")} />}

          <Box sx={{ display: showOtpInput ? "none" : "block" }}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!(errors.email === undefined)}
                  helperText={errors.email?.message}
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!(errors.password === undefined)}
                  helperText={errors.password?.message}
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={togglePassword} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>

          {showOtpInput && (
            <>
              <Controller
                name="otpAttempt"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!(errors.otpAttempt === undefined)}
                    helperText={errors.otpAttempt?.message}
                    margin="normal"
                    required
                    fullWidth
                    id="otpAttempt"
                    label="認証コード / OTP"
                    name="otpAttempt"
                    autoComplete="otpAttempt"
                    autoFocus
                  />
                )}
              />
              <List
                sx={{
                  listStyle: "disc",
                  paddingLeft: 3,
                }}
              >
                <ListItem sx={{ display: "list-item", px: 1 }}>
                  <Typography variant="body2">メールに送信された、認証コードを入力してください。</Typography>
                </ListItem>
                <ListItem sx={{ display: "list-item", px: 1 }}>
                  <Typography variant="body2">
                    ニトエルではセキュリティ強化のため、メールアドレスによる二段階認証を行っております。メールをご確認いただき、認証番号を入力してください。
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: "list-item", px: 1 }}>
                  <Typography variant="body2">有効期限は10分です。</Typography>
                </ListItem>
                <ListItem sx={{ display: "list-item", px: 1 }}>
                  <Typography variant="body2">
                    Your OTP has been sent to your email. Please check your email and enter the OTP above. The OTP is valid for 10
                    minutes.
                  </Typography>
                </ListItem>
              </List>
            </>
          )}

          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={isSubmitting}>
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Typography component="span" variant="body2">
                メールアドレスの大文字と小文字を区別します。大文字と小文字を正確に入力下さい。 Uppercase and lowercase are
                distinguished in email addresses. Please enter them accurately.
              </Typography>
            </Grid>
          </Grid>
          {!showOtpInput && (
            <Grid container>
              <Grid item xs>
                <Link href="/forget_password" passHref>
                  <MuiLink variant="body2">パスワードを設定される方はこちら / Click here to reset password</MuiLink>
                </Link>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      layout: "none",
    },
  };
};

export default Login;
