import React from "react";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";

type ErrMsgsProps = {
  msgs: string[];
};

const ErrMsgs: React.FC<ErrMsgsProps> = (props) => {
  const { msgs } = props;

  return (
    <Alert severity="error">
      {msgs.map((msg) => (
        <Typography key={msg}>{msg}</Typography>
      ))}
    </Alert>
  );
};

export default ErrMsgs;
